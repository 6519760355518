
import React from 'react'
import Layout from './layout'
import Portrait from '../pages/allegra_portrait_2024.png'
import styled from 'styled-components'
import { mobileOnlyRule, desktopOnlyRule } from '../components/breakpoints'

const Style = styled.div`
    ${desktopOnlyRule} {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 40px;
    }

    ${mobileOnlyRule} {
      display: flex;
      flex-wrap: wrap;
    }

    img {
      width: 100%;
    }

`

const LeftCol = styled.div`
  ${desktopOnlyRule} {
    grid-column: span 4;
  }
  ${mobileOnlyRule} {
    width: 100%;
  }
`
const RightCol = styled.div`
  ${desktopOnlyRule} {
    grid-column: span 8;
  }
  ${mobileOnlyRule} {
    width: 100%;
  }

`


export default function HomeLayout(props) {

    const layoutProps = {...props, children: null}
    const { children } = props;

    return <Layout {...layoutProps}>
      <Style>
        <LeftCol>
          <img alt="portrait" src={Portrait} style={{borderRadius:'50%',objectFit:'cover',aspectRatio:'1/1'}}/>
        </LeftCol>
        <RightCol>
          { children }
        </RightCol>
      </Style>
    </Layout>
}
