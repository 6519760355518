
export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const mobileOnlyRule = `@media (max-width: 768px)`
export const desktopOnlyRule = `@media (min-width: 769px)`
