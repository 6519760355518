import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import HomeLayout from '../components/homeLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomeLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} mdxType="SEO" />
    <p>{`Allegra H. Fullerton is a doctoral researcher at the University of Colorado Denver School of Public Affairs. Her research is centered around emotions and politics, gender justice and marginalization, policy theories, and network methods. She has published in Policy Studies Journal, Review of Policy Research, International Review of Public Policy, and more on topics spanning emotions, policy feedback in the US and Germany, transgender healthcare, power, and policy learning. She teaches courses on policy processes and democracy as well as negotiation. She sits on the organizing committee for the Conference on Policy Process Research, a community dedicated to advancing policy process theories and methods internationally. She also serves as the Digital Associate Editor at Policy and Politics. She also works at the Center of Policy and Democracy and leads several collaborative research initiatives centered on transgender policy, intersectionality, and improving measurements of coalitions and emotions.  `}</p>
    <Link to="/resume/" mdxType="Link">
  <Button marginTop="35px" mdxType="Button">CV</Button>
    </Link>
    <div style={{
      visibility: 'hidden'
    }}>
      <Link to="/blog/" mdxType="Link">
  <Button marginTop="35px" mdxType="Button">Go to Blog</Button>
      </Link>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      